import styled from 'styled-components'

export const BridgeFormContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin: 0px auto;
    max-width: 700px;
    box-sizing: border-box;
    width: 100%;
    padding: 10px;
`

export const FormBox = styled.div`
    display: flex;
    justify-content: space-between;
    gap: 30px;
    padding: 5px;
    @media (max-width: 768px) {
        flex-direction: column;
    }
`

export const BridgeCoin = styled.p`
    font-family: "Roboto Mono", monospace;
    font-size: 24px;
    text-align: center;
    @media (max-width: 768px) {
        font-size: 32px;
        margin: 0;
    }
`

export const BridgeInputContainer = styled.div`
    display: flex;
    background-color: white;
    border-radius: 80px;
    padding: 12px 20px 12px 40px;
    width: 100%;
    box-sizing: border-box;
    position: relative;
    min-width: 300px;
    @media (max-width: 768px) {
        border-radius: 24px;
        flex-direction: column;
    }
`

export const BridgeInput = styled.input`
    color: #000000;
    font-family: 'SF Mono', sans-serif;
    font-weight: 500;
    font-size: 18px;
    letter-spacing: -0.01em;
    text-transform: uppercase;
    border: none;
    width: 100%;
    &::placeholder {
        color: rgba(0, 0, 0, 0.4);
        letter-spacing: -0.01em;
        text-transform: uppercase;
        font-family: 'SF Mono', sans-serif;
        font-weight: 500;
        font-size: 18px;
        line-height: 14px;
    }
    &:focus {
        border: none;
        outline: 0;
    }
      
    @media (max-width: 768px) {
        margin: 19px 10px;
    }
`

export const BridgeInfoContainer = styled.div`
    padding-left: 140px;
    @media (max-width: 768px) {
        padding-left: 0;
        margin-top: 10px;
    }
`

export const BridgeBalanceInfo = styled.p`
    font-family: 'SF Mono', sans-serif;
    font-size: 14px;
    @media (max-width: 768px) {
        font-size: 18px;
        text-align: center;
    }
`

export const SpinnerContainer = styled.div`
    display: flex;
    justify-content: center;
    margin: auto 0;
    box-sizing: border-box;
    max-width: 143px;
    width: 100%;
    @media (max-width: 768px) {
        margin: 0 auto;
        height: 66px;
    }
`

export const BridgeError = styled.p`
    font-family: 'SF Mono', sans-serif;
    font-size: 14px;
    color: #D44333;
    line-height: 14px;
    margin: 0 0 140 0;
    @media (max-width: 768px) {
        font-size: 18px;
        text-align: center;
        margin: 0;
    }
`

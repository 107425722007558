import styled from "styled-components"

export const StyledButton = styled.button`
    background: ${({ status }) => {
        if (status === "selected") {
        return 'rgba(249, 213, 73, 0.2)'
        }
        return '#F9D549'
    }};
    border-radius: ${({ variant }) => {
        if (variant === "left") {
            return '48px 0 0 48px'
        } else if (variant === "right") {
            return '0 48px 48px 0'
        }
        return '48px'
    }};
    font-family: 'SF Mono', sans-serif;
    font-weight: 700;
    font-size: 16px;
    line-height: 14px;
    text-align: center;
    letter-spacing: -0.01em;
    text-transform: uppercase;
    color: rgba(0, 0, 0, 0.9);
    padding: ${({ variant, status }) => {
        if (variant === "bridge") {
            return '18px 42.5px'
        } else if (status === "selected") {
            return '10px 40px'
        }
        return '18px 33px'
    }};
    border: none;
    cursor: ${({ status }) => {
        if (status === "selected") {
        return 'auto'
        }
        return 'pointer'
    }};
    transition: transform ease-in 0.1s, box-shadow ease-in 0.25s;
    &:disabled,
    &[disabled] {
        cursor: not-allowed;
        color: rgba(0, 0, 0, 0.1);
    }
    &:disabled:hover&:after,
    &[disabled]:hover&:after {
        display:none;
    }
    &:disabled&:active,
    &[disabled]&:active {
        transform: none;
    }
    &:active {
        transform: ${({ status }) => {
            if (status === "selected") {
                return 'none'
            }
            return 'scale(0.9)'
        }};
    }
    @media (max-width: 768px) {
        padding: ${({ status }) => {
            if (status === "selected") {
                return '22px 33px'
            }
            return '26px 33px'
        }};
    }
`

import { ethers } from "ethers"
import Spinner from "../Spinner"
import { Box, FetchingBox, TxFeeText } from "./TxFeeBox.styled"
import useMediaQuery from '@mui/material/useMediaQuery'

function TxFeeBox({ isFetchingFee, feeInfo, gasFee }) {
    const matches = useMediaQuery('(max-width: 768px)')
    /* global BigInt */
    return <Box>
        {isFetchingFee ? <FetchingBox>
            <Spinner size={matches? 30 : 20} color="black" />
            <TxFeeText>Fetching bridge conditions...</TxFeeText>
        </FetchingBox> :
            !feeInfo ? <TxFeeText type="error">Error: couldn't fetch tx conditions</TxFeeText> : feeInfo.isBridgeAvailable ?
                <>
                    <TxFeeText>Provided Amount: {ethers.utils.formatEther(BigInt(feeInfo.providedAmount))} FITFI</TxFeeText>
                    <TxFeeText>Minimum Amount: {ethers.utils.formatEther(BigInt(feeInfo.thresholdWei))} FITFI</TxFeeText>
                    <TxFeeText>Transaction Cost: ≈{gasFee} AVAX</TxFeeText>
                    <TxFeeText>Gas Fee: {ethers.utils.formatEther(BigInt(feeInfo.minFeeAmount))} FITFI</TxFeeText>
                    <TxFeeText>Bridging Fee: {feeInfo.feePercent}%</TxFeeText>
                    <TxFeeText>You'll receive on STEP: {ethers.utils.formatEther(BigInt(feeInfo.bridgeResultAmount))} FITFI</TxFeeText>
                </>
                :
                <>
                    <TxFeeText>Bridge is unavailable.</TxFeeText>
                    <TxFeeText>Minimum amount for bridge: {ethers.utils.formatEther(BigInt(feeInfo.thresholdWei))} FITFI</TxFeeText>
                </>
        }
    </Box>
}

export default TxFeeBox

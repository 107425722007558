import styled from "styled-components"

export const Modal = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(227, 227, 227, 0.9);

    justify-content: center;
    align-items: center;
    display: flex;
    z-index: 100;
    transition: visibility 0.5s, opacity 0.5s ease;

    visibility: ${({ isOpen }) => {
        if (isOpen) {
            return 'visible'
        }
        return 'hidden'
    }};

    opacity: ${({ isOpen }) => {
        if (isOpen) {
            return '1'
        }
        return '0'
    }};
`

export const ModalContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 610px;
    background-color: white;
    border-radius: 40px;
    padding: 60px 30px;
    gap: 30px;
    position: relative;
`

export const ModalText = styled.h1`
    font-family: 'SF Mono', sans-serif;
    font-weight: 400;
    font-size: 24px;
    text-transform: uppercase;
    text-align: center;
`

export const ButtonLinkWrapper = styled.a`
  text-decoration: none;
`

export const CloseButton = styled.button`
    position: absolute;
    top: 30px;
    right: 40px;
    background-color: transparent;
    transform: scale(0.9);
    border: none;
    padding: 0;
    margin: 0;
    cursor: pointer;
    &:hover {
        box-shadow: rgba(0,0,0, 0.2) 0px 0.1px 0px 0px;
    }
`

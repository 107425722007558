import styled from "styled-components"

export const HeaderContainer = styled.div`
    padding: 24px 24px; 
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: 200px;
    height: 75px;
    @media (max-width: 768px) {
        height: auto;
        flex-direction: column;
        gap: 24px;
        margin-bottom: 60px;
    }
`

export const LogoContainer = styled.a`
  display: block;
  margin: auto 0;
  margin-left: -35px;
  text-decoration: none;
`

export const LogoName = styled.h1`
  font-family: 'Roboto Mono', monospace;
  font-weight: 500;
  font-size: 24px;
  line-height: 20px;
  letter-spacing: -0.04em;
  text-indent: 32px;
  text-transform: uppercase;
  color: black;
  margin: 0;
  padding-left: ${({ variant }) => {
    if (variant === "withPadding") {
      return '32px'
    }
    return '0'
  }};
  @media (max-width: 768px) {
    font-size: 40px;
    font-weight: 400;
    line-height: 34px;
  }
`

export const HeaderInfoNetworkContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 50px;
    margin-left: auto;
    margin-top: 0;
    @media (max-width: 768px) {
      flex-wrap: wrap;
      gap: 20px;      
      justify-content: flex-end;
      margin-top: 40px;
    }
`

export const HeaderInfo = styled.h1`
  color: rgba(0, 0, 0, 0.4);
  font-weight: 500;
  font-size: 16px;
  letter-spacing: -0.01em;
  text-transform: uppercase;
  font-family: 'SF Mono', sans-serif;
  margin: 0;
  @media (max-width: 768px) {
    font-size: 16px;
  }
`

export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  @media (max-width: 768px) {
    margin-left: auto;
    margin-top: 73px;
  }
  @media (max-width: 482px) {
    margin-left: auto;
    margin-top: 112px;
  }
`

export const ButtonLinkWrapper = styled.a`
  text-decoration: none;
`

export const ButtonTitle = styled.h1`
  font-weight: 700;
  font-size: 16px;
  font-family: 'SF Mono', sans-serif;
  margin: 5px;
  text-align: center;
  line-height: 24px;
`
export const ButtonText = styled.p`
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  font-family: 'SF Mono', sans-serif;
  margin: 5px;
  text-align: center;
  text-transform: none;
`

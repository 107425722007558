import { HeaderContainer, LogoContainer, LogoName, HeaderInfoNetworkContainer, HeaderInfo, ButtonContainer, ButtonTitle, ButtonText } from "./Header.styled"
import { BLOCKSCOUT_URL } from "./../../constants"
import Button from "../Button"
import { utils } from 'ethers'
import networks from './../../networks.json'
import { FUJI_CHAIN_ID } from '../../constants'
import { useEffect, useState } from "react"
import { shortenAddress } from "./../../utils/shortenAddress"
import NoWalletModal from "../NoWalletModal"
import React from "react"

function Header({ userAddress, chainId, setUserAddress }) {
    const [isModalOpen, setIsModalOpen] = useState(false)

    function ConnectButton() {
        if (!window.ethereum) {
            return (
                <ButtonContainer>
                    <Button onClick={() => setIsModalOpen(true)}>Connect wallet</Button>
                </ButtonContainer>
            )
        }
        return <ButtonContainer>
            <Button onClick={connect}>Connect wallet</Button>
        </ButtonContainer>
    }

    async function connect() {
        const accounts = await window.ethereum.request({
            method: 'eth_requestAccounts',
        })
        setUserAddress(utils.getAddress(accounts[0]))
    }

    async function switchChain(id) {
        try {
            await window.ethereum.request({
                method: 'wallet_switchEthereumChain',
                params: [{ chainId: networks[id].params.chainId }],
            })
        } catch (error) {
            if (error.code === 4902 || error.code === -32603) {
                try {
                    await window.ethereum.request({
                        method: 'wallet_addEthereumChain',
                        params: [networks[id].params],
                    })
                } catch (error) {
                    console.error(error)
                }
            } else {
                console.error(error)
            }
        }
    }

    async function checkIfWalletIsConnected() {
        if (window.ethereum) {
            const accounts = await window.ethereum.request({
                method: 'eth_accounts',
            })

            if (accounts.length > 0) {
                const account = accounts[0]
                setUserAddress(utils.getAddress(account))
            }
        }
    }

    function handleModalClose() {
        setIsModalOpen(false)
    }

    useEffect(() => {
        checkIfWalletIsConnected()
    })

    return <>
        <HeaderContainer>
            <LogoContainer href={BLOCKSCOUT_URL} target="_blank" rel="noreferrer">
                <LogoName variant="withPadding">Step</LogoName>
                <LogoName>Bridge</LogoName>
            </LogoContainer>
            {userAddress && (chainId === networks[FUJI_CHAIN_ID].params.chainId) ?
                <HeaderInfoNetworkContainer>
                    <HeaderInfo>Address: {shortenAddress(userAddress)}</HeaderInfo>
                    <div>
                        <Button status="selected">
                            <ButtonTitle>Avax <span style={{fontWeight: "normal", textTransform: "none"}}>to</span> Step</ButtonTitle>
                            <ButtonText>migration</ButtonText>
                        </Button>
                    </div>
                </HeaderInfoNetworkContainer> : userAddress && chainId ? <ButtonContainer><Button onClick={() => switchChain(FUJI_CHAIN_ID)}>Switch network</Button></ButtonContainer> :
                    <ConnectButton />
            }
        </HeaderContainer>
        <NoWalletModal isOpen={isModalOpen} onClose={handleModalClose} />
    </>
}

export default Header


import styled from 'styled-components'

export const Box = styled.div`
    background-color: rgba(249, 213, 73, 0.3);
    margin: 0 70px 10px 90px;
    padding: 10px 20px 10px 40px;
    border-radius: 48px;
    max-width: 400px;
    width: 100%;
    border-box: box-sizing;
    @media (max-width: 768px) {
        padding: 10px;
        max-width: 600px;
        margin: 0 auto;
        padding: 10px 0;
    }
`

export const FetchingBox = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
    padding-left: 10px;
    @media (max-width: 768px) {
        flex-direction: column;
        justify-content: center;
        padding: 10px;
        gap: 0;
    }
`

export const TxFeeText = styled.p`
    font-family: 'SF Mono', sans-serif;
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
    margin: 10px;
    color: ${({ type }) => {
        if (type === "error") {
        return '#D44333'
        }
        return 'black'
    }};
    @media (max-width: 768px) {
        font-size: 18px;
        line-height: 24px;
        text-align: center;
    }
`

import { StyledButton } from "./Button.styled"

export default function Button({ children,
    disabled,
    variant,
    status,
    onClick }) {
    return <StyledButton disabled={disabled} variant={variant} status={status} onClick={onClick}>
        {children}
    </StyledButton>
}

import { TxLink, TxLinkContainer, TxLinkTextContainer, TxStatusContainer, TxStatusTitle, CloseButton, TxLinkText, TxNumber } from "./TxStatusBox.styled"
import CloseIcon from '@mui/icons-material/Close'
import useMediaQuery from '@mui/material/useMediaQuery'
import LinkIcon from "../../images/LinkIcon"

function TxStatusBox({ number, txStatus, handleClose, blockexplorer, txHash }) {
    const matches = useMediaQuery('(max-width:768px)')

    return <TxStatusContainer variant={txStatus.error ? "error" : txStatus.success ? "success" : "loading"}>
        <CloseButton onClick={handleClose}>
            <CloseIcon sx={{ color: "white", fontSize: matches ? "24px" : "32px" }} />
        </CloseButton>
        <TxNumber>{number}tx</TxNumber>
        <TxStatusTitle>{txStatus.error ? 'Something went wrong ...' : txStatus.success ? 'Success!' : 'Waiting...'}</TxStatusTitle>
        {txHash && <TxLinkContainer>
            <TxLinkTextContainer>
                <TxLinkText>Here&apos;s your transaction link:</TxLinkText>
                <TxLink href={`${blockexplorer}/tx/${txHash}`} target="_blank" rel="noreferrer">{blockexplorer}</TxLink>
            </TxLinkTextContainer>
            {!matches && <TxLink href={`${blockexplorer}/tx/${txHash}`} target="_blank" rel="noreferrer">
                <LinkIcon isError={txStatus.error} />
            </TxLink>}
        </TxLinkContainer>}
    </TxStatusContainer>
}

export default TxStatusBox

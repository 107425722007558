import { ethers } from "ethers"
import { FITFI_CONTRACT_ADDRESS } from "./../../constants"
import FitfiContractAbi from "./../../abis/FitfiContractAbi.json"
import { BRIDGE_C_ADDRESS } from "./../../constants"

export function getFitfiToken() {
    const provider = new ethers.providers.Web3Provider(window.ethereum)
    const signer = provider.getSigner()
    const FitfiToken = new ethers.Contract(
        FITFI_CONTRACT_ADDRESS,
        FitfiContractAbi,
        signer,
    )
    return FitfiToken
}

export async function getBridgeGas(value) {
    try {
        const FitfiToken = getFitfiToken()
        const gasLimit = await FitfiToken.estimateGas.transfer(BRIDGE_C_ADDRESS, value)
        const feeData = await FitfiToken.provider.getFeeData()
        const gasFee = gasLimit.mul(feeData.gasPrice)
        return gasFee
    } catch (e) {
        console.log(e)
    }
} 

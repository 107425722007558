import { useState, useEffect } from 'react'
import './App.css'
import { AppContainer } from './components/AppContainer'
import BridgeForm from './components/BridgeForm'
import Header from './components/Header'
import { STEP_CHAIN_ID, FUJI_CHAIN_ID } from './constants/'
import networks from './networks.json'
import { utils } from 'ethers'
import { getFitfiToken } from "./api/contract"

function App() {
  const [userAddress, setUserAddress] = useState('')
  const [userBalance, setUserBalance] = useState('0.0000')
  const [userBalanceWei, setUserBalanceWei] = useState()
  const [chainId, setChainId] = useState('')
  const [fitfiTokenBalance, setFitfiTokenBalance] = useState('0.0000')
  const [fitfiTokenBalanceWei, setFitfiTokenBalanceWei] = useState()

  function onAddressChanged(accounts) {
    if (accounts.length > 0) {
      setUserAddress(utils.getAddress(accounts[0]))
    } else {
      setUserAddress('')
    }
  }

  function onChainChanged() {
    setUserBalance('0.0000')
    setUserBalanceWei()
    setFitfiTokenBalance('0.0000')
    setFitfiTokenBalanceWei()
    window.ethereum
      .request({ method: 'eth_chainId' })
      .then((chain) => setChainId(chain))
  }

  async function getBalance() {
    if (userAddress) {
      try {
        const hexBalance = await window.ethereum.request({
          method: 'eth_getBalance',
          params: [userAddress, 'latest']
        })
        const num = Number(utils.formatEther(hexBalance))
        setUserBalance(num.toFixed(4))
        setUserBalanceWei(hexBalance)
        return num.toFixed(4)
      } catch (error) {
        console.log(error)
      }
    }
  }

  async function getFitfiBalance() {
    try {
      const FitfiToken = getFitfiToken()
      const hexFitfiBalance = await FitfiToken.balanceOf(userAddress)
      const fitfi = Number(utils.formatEther(hexFitfiBalance))
      setFitfiTokenBalance(fitfi.toFixed(4))
      setFitfiTokenBalanceWei(hexFitfiBalance)
      return fitfi.toFixed(4)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    if (userAddress) {
      window.ethereum
        .request({ method: 'eth_chainId' })
        .then((chain) => setChainId(chain))
    }
  }, [userAddress])

  useEffect(() => {
    if (userAddress && (chainId === networks[STEP_CHAIN_ID].params.chainId || chainId === networks[FUJI_CHAIN_ID].params.chainId)) {
      getBalance()
    }
    if (userAddress && userBalance && chainId === networks[FUJI_CHAIN_ID].params.chainId) {
      getFitfiBalance()
    }
  }, [userAddress, userBalance, chainId])

  useEffect(() => {
    if (window.ethereum) {
      window.ethereum.on('accountsChanged', onAddressChanged)
      window.ethereum.on('chainChanged', onChainChanged)
    }
    return () => {
      if (window.ethereum) {
        window.ethereum.removeListener('accountsChanged', onAddressChanged)
        window.ethereum.removeListener('chainChanged', onChainChanged)
      }
    }
  }, [])


  return (
    <AppContainer>
      <Header userAddress={userAddress} chainId={chainId} setUserAddress={setUserAddress} setChainId={setChainId} />
      <BridgeForm userAddress={userAddress} userBalance={userBalance} userBalanceWei={userBalanceWei} chainId={chainId} fitfiTokenBalance={fitfiTokenBalance} fitfiTokenBalanceWei={fitfiTokenBalanceWei} getBalance={getBalance} getFitfiBalance={getFitfiBalance} />
    </AppContainer>
  )
}

export default App

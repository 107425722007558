import Button from "../Button"
import { BridgeFormContainer, FormBox, SpinnerContainer, BridgeInputContainer, BridgeInput, BridgeCoin, BridgeBalanceInfo, BridgeInfoContainer, BridgeError } from "./BridgeForm.styled"
import networks from "./../../networks.json"
import { STEP_CHAIN_ID, FUJI_CHAIN_ID, BRIDGE_STEP_ADDRESS, BRIDGE_C_ADDRESS } from "./../../constants"
import { getFitfiToken, getBridgeGas } from "../../api/contract"
import { useEffect, useState } from "react"
import Spinner from "../Spinner"
import { ethers } from "ethers"
import TxStatusBox from "../TxStatusBox"
import { getTxStatus } from "./../../api/getTxStatus"
import { getConditions } from "../../api/getConditions"
import TxFeeBox from "../TxFeeBox"
import useMediaQuery from '@mui/material/useMediaQuery'

function BridgeForm({ userAddress, userBalance, userBalanceWei, chainId, fitfiTokenBalance, fitfiTokenBalanceWei, getBalance, getFitfiBalance }) {
    const [amount, setAmount] = useState("")
    const [firstTxBlockexplorer, setFirstTxBlockexplorer] = useState()
    const [secondTxBlockexplorer, setSecondTxBlockexplorer] = useState()
    const [firstTxHash, setFirstTxHash] = useState("")
    const [secondTxHash, setSecondTxHash] = useState("")
    const [firstTxBoxOpen, setFirstTxBoxOpen] = useState(false)
    const [secondTxBoxOpen, setSecondTxBoxOpen] = useState(false)
    const [firstTxStatus, setFirstTxStatus] = useState({
        isLoading: false,
        success: false,
        error: false,
    })
    const [secondTxStatus, setSecondTxStatus] = useState({
        isLoading: false,
        success: false,
        error: false,
    })
    const [isError, setIsError] = useState(false)
    const [errorMessage, setErrorMessage] = useState("")
    const [isFeeBoxOpen, setIsFeeBoxOpen] = useState(false)
    const [isFetchingFee, setIsFetchingFee] = useState(false)
    const [feeInfo, setFeeInfo] = useState(null)
    const [typingTimeout, setTypingTimeout] = useState(null)
    const [gasFee, setGasFee] = useState()
    const isMobile = useMediaQuery('(max-width:768px)')

    function clearFeeInfo() {
        setFeeInfo()
        setGasFee()
        setIsFeeBoxOpen(false)
        setIsFetchingFee(false)
    }

    const handleInput = (e) => {
        clearFeeInfo()
        if (isError) {
            setIsError(false)
        }
        const value = e.target.value
        if (value.length <= 20) {
            const isFirstInteger = /^[0-9]/g.test(value)
            if (value === "") {
                setAmount(value)
                clearFeeInfo()
            } else if (isFirstInteger) {
                if (secondTxStatus.success) {
                    handleCloseFirstTxBox()
                    handleCloseSecondTxBox()
                }
                const isIntegerDot = /^[0-9.]*$/g.test(value)
                if (isIntegerDot) {
                    const multiplyDots = /\..*\./g.test(value)
                    if (multiplyDots) {
                        if (Number(value)) {
                            setAmount(value)
                            return
                        } else {
                            clearFeeInfo()
                            return
                        }
                    } else {
                        setAmount(value)
                        if (!Number(value)) {
                            setIsFetchingFee(false)
                            setIsFeeBoxOpen(false)
                        }
                    }
                }
            }
        }
    }

    function handleCloseFirstTxBox() {
        setFirstTxBoxOpen(false)
        setFirstTxHash("")
    }

    function handleCloseSecondTxBox() {
        setSecondTxBoxOpen(false)
        setSecondTxHash("")
    }

    function getSecondTxStatus(hash) {
        getTxStatus(hash)
            .then((res) => {
                if (chainId === networks[FUJI_CHAIN_ID].params.chainId && res.result && res.result.tx_hash_step) {
                    setSecondTxHash(res.result.tx_hash_step)
                } else if (chainId === networks[STEP_CHAIN_ID].params.chainId && res.result && res.result.tx_hash_c_chain) {
                    setSecondTxHash(res.result.tx_hash_c_chain)
                }
                if (res.result && res.result.status === "MINED_IN_DESTINATION_NETWORK") {
                    setTimeout(updateBalance, 3000, userBalance, fitfiTokenBalance)
                    setSecondTxStatus({ isLoading: false, success: true, error: false })
                    setAmount("")
                } else if (res.result && res.result.status === "ERROR_ON_SENDING_TO_DESTINATION_NETWORK") {
                    setSecondTxStatus({ isLoading: false, success: false, error: true })
                } else {
                    setTimeout(getSecondTxStatus, 2000, hash)
                }
            })
            .catch((e) => {
                setSecondTxStatus({ isLoading: false, success: false, error: true })
                console.log(e)
            })
    }

    async function updateBalance(beforeTxBalance, beforeTxFitfiBalance) {
        const newBalance = await getBalance()
        if (newBalance !== beforeTxBalance) {
            setTimeout(updateFitfiTokenBalance, 3000, beforeTxFitfiBalance)
        } else {
            setTimeout(updateBalance, 3000, beforeTxBalance, beforeTxFitfiBalance)
        }
    }

    async function updateFitfiTokenBalance(beforeTxFitfiBalance) {
        if (fitfiTokenBalance === beforeTxFitfiBalance) {
            const newBalance = await getFitfiBalance()
            if (newBalance === beforeTxFitfiBalance) {
                setTimeout(updateFitfiTokenBalance, 3000, beforeTxFitfiBalance)
            }
        }
    }

    async function bridge() {
        setIsError(false)
        setFirstTxStatus({ isLoading: true, success: false, error: false });
        setFirstTxBoxOpen(false)
        setSecondTxBoxOpen(false)
        setFirstTxBlockexplorer(networks[FUJI_CHAIN_ID].params.blockExplorerUrls[0])
        setSecondTxBlockexplorer(networks[STEP_CHAIN_ID].params.blockExplorerUrls[0])
        const inputValue = ethers.utils.parseEther(amount)
        try {
            const FitfiToken = getFitfiToken()
            let gasDetails = {}
            if (isMobile) {
                const gasLimit = await FitfiToken.estimateGas.transfer(BRIDGE_C_ADDRESS, inputValue)
                const provider = new ethers.providers.Web3Provider(window.ethereum)
                const gasPrice = await provider.getGasPrice()
                gasDetails = {
                    gasLimit, 
                    gasPrice
                }
            }
            FitfiToken.transfer(BRIDGE_C_ADDRESS, inputValue, gasDetails)
                .then(
                    (tx) => {
                        setFirstTxHash(tx.hash)
                        setFirstTxBoxOpen(true)
                        tx.wait()
                            .then((res) => {
                                const hash = res.transactionHash;
                                setFirstTxStatus({ isLoading: false, success: true, error: false })
                                setSecondTxStatus({ isLoading: true, success: false, error: false })
                                setSecondTxBoxOpen(true)
                                setTimeout(getSecondTxStatus, 1000, hash)
                            })
                            .catch((err) => {
                                setFirstTxStatus({ isLoading: false, success: false, error: true })
                                console.log(err)
                            })
                    })
                .catch((err) => {
                    setFirstTxStatus({ isLoading: false, success: false, error: true })
                    console.log(err)
                })
        } catch (e) {
            setErrorMessage("Something went wrong. Try again")
            setIsError(true)
            setFirstTxStatus({ isLoading: false, success: false, error: false })
            console.log(e)
        }
    }

    async function unBridge() {
        setIsError(false)
        setFirstTxStatus({ isLoading: true, success: false, error: false })
        setFirstTxBoxOpen(false)
        setSecondTxBoxOpen(false)
        setFirstTxBlockexplorer(networks[STEP_CHAIN_ID].params.blockExplorerUrls[0])
        setSecondTxBlockexplorer(networks[FUJI_CHAIN_ID].params.blockExplorerUrls[0])
        try {
            const inputValue = ethers.utils.parseEther(amount)
            const provider = new ethers.providers.Web3Provider(window.ethereum)
            const signer = provider.getSigner()
            const tx = {
                to: BRIDGE_STEP_ADDRESS,
                from: await signer.getAddress(),
                value: inputValue,
            }
            const gasLimit = await provider.estimateGas(tx)
            const feeData = await provider.getFeeData()
            const totalCost = inputValue.add(feeData.gasPrice.mul(gasLimit))
            if (totalCost.gt(userBalanceWei)) {
                setFirstTxStatus({ isLoading: false, success: false, error: false })
                setErrorMessage("Insufficient FITFI funds")
                setIsError(true)
            } else {
                signer.sendTransaction(tx).then(
                    (tx) => {
                        setFirstTxBoxOpen(true)
                        setFirstTxHash(tx.hash)
                        tx.wait()
                            .then((res) => {
                                const hash = res.transactionHash;
                                setFirstTxStatus({ isLoading: false, success: true, error: false })
                                setSecondTxStatus({ isLoading: true, success: false, error: false })
                                setSecondTxBoxOpen(true)
                                setTimeout(getSecondTxStatus, 1000, hash)
                            })
                            .catch((err) => {
                                setFirstTxStatus({ isLoading: false, success: false, error: true })
                                console.log(err)
                            })
                    })
                    .catch((err) => {
                        setFirstTxStatus({ isLoading: false, success: false, error: true })
                        console.log(err)
                    })
            }
        } catch (e) {
            setErrorMessage("Something went wrong. Try again")
            setIsError(true)
            setFirstTxStatus({ isLoading: false, success: false, error: false })
            console.log(e)
        }
    }

    const BridgeButton = () => {
        return <Button disabled={!userAddress || !userBalance || chainId !== networks[FUJI_CHAIN_ID].params.chainId || firstTxStatus.isLoading || Number(amount) === 0 || isFetchingFee || !feeInfo || !feeInfo.isBridgeAvailable} onClick={bridge} variant="bridge">Bridge</Button>
    }

    useEffect(() => {
        function getTxFee(value, type) {
            if (value.gt(fitfiTokenBalanceWei)) {
                clearFeeInfo()
                setErrorMessage("Insufficient FITFI Token amount")
                setIsError(true)
                return
            } else {
                getConditions(value, type)
                    .then((res) => {
                        if (res.result) {
                            setFeeInfo(res.result)
                            getBridgeGas(value)
                                .then((res) => {
                                    if (res && res.lt(userBalanceWei)) {
                                        setGasFee(ethers.utils.formatEther(res))
                                        setIsFetchingFee(false)
                                    } else {
                                        setErrorMessage("Insufficient AVAX funds")
                                        setIsError(true)
                                        clearFeeInfo()
                                    }
                                })
                                .catch((e) => {
                                    setErrorMessage("Error: couldn't get gas fee")
                                    setIsError(true)
                                    console.log(e)
                                })
                        }
                    })
                    .catch((e) => {
                        setIsFetchingFee(false)
                        console.log(e)
                    })
            }
        }
        setErrorMessage("")
        setIsError(false)
        if (userAddress && userBalance && userBalanceWei && fitfiTokenBalance && fitfiTokenBalanceWei && chainId === networks[FUJI_CHAIN_ID].params.chainId && Number(amount)) {
            const inputValue = ethers.utils.parseEther(amount)
            setIsFetchingFee(true)
            setIsFeeBoxOpen(true)
            clearTimeout(typingTimeout)
            setTypingTimeout(setTimeout(getTxFee, 2000, inputValue, "BRIDGE"))
        } else {
            clearFeeInfo()
        }

        return () => clearTimeout(typingTimeout)
    }, [amount, userAddress, userBalance, userBalanceWei, fitfiTokenBalance, fitfiTokenBalanceWei, chainId])

    return <BridgeFormContainer>
        <FormBox>
            <BridgeCoin>FITFI</BridgeCoin>
            <BridgeInputContainer>
                <BridgeInput placeholder="0.0000" value={amount} onChange={handleInput} />
            </BridgeInputContainer>
            {firstTxStatus.isLoading && !isError ? <SpinnerContainer><Spinner /></SpinnerContainer> : <BridgeButton />}
        </FormBox>
        {userAddress && userBalance && (chainId === networks[FUJI_CHAIN_ID].params.chainId) &&
            (<BridgeInfoContainer>
                <BridgeBalanceInfo>Balance: {chainId === networks[STEP_CHAIN_ID].params.chainId ? userBalance : fitfiTokenBalance} {chainId === networks[STEP_CHAIN_ID].params.chainId ? networks[STEP_CHAIN_ID].params.nativeCurrency.symbol : "FITFI (ERC-20) Token"}</BridgeBalanceInfo>
                {isError && <BridgeError>{errorMessage}</BridgeError>}
            </BridgeInfoContainer>)}
        {isFeeBoxOpen && <TxFeeBox isFetchingFee={isFetchingFee} feeInfo={feeInfo} gasFee={gasFee} />}
        {firstTxBoxOpen && <TxStatusBox number={1} txStatus={firstTxStatus} handleClose={handleCloseFirstTxBox} blockexplorer={firstTxBlockexplorer} txHash={firstTxHash} />}
        {secondTxBoxOpen && <TxStatusBox number={2} txStatus={secondTxStatus} handleClose={handleCloseSecondTxBox} blockexplorer={secondTxBlockexplorer} txHash={secondTxHash} />}
    </BridgeFormContainer>
}

export default BridgeForm

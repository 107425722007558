import styled from 'styled-components'

export const TxStatusContainer = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 30px;
    margin: 30px 0;   
    background-color: ${({ variant }) => {
        if (variant === "error") {
            return '#D44333'
        } else if (variant === "success") {
            return "rgba(0, 51, 231, 1)"
        }
        return 'rgba(0, 51, 231, 0.6)'
    }};
    color: white;
    border-radius: 40px;
    padding: 60px 0;
    max-width: 100%;
    border-box: box-sizing;
    @media (max-width: 768px) {
        padding: 40px 10px;
        margin: 40px 0;   
        gap: 40px;
    }
`

export const CloseButton = styled.button`
    position: absolute;
    top: 30px;
    right: 40px;
    background-color: transparent;
    transform: scale(0.9);
    border: none;
    padding: 0;
    margin: 0;
    cursor: pointer;
    &:hover {
        box-shadow: rgba(255,255,255, 0.2) 0px 7px 29px 0px;
    }
`

export const TxStatusTitle = styled.h1`
    font-family: 'Roboto Mono', monospace;
    font-weight: 400;
    font-size: 24px;
    line-height: 18px;
    letter-spacing: -0.04em;
    text-transform: uppercase;
    color: white;
    margin: 0;
    @media (max-width: 768px) {
        font-size: 20px;
    }
`

export const TxLinkContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 80px;
    background-color: white;
    border-radius: 80px;
    padding: 10px 30px 20px;
    @media (max-width: 768px) {
        justify-content: center;
        font-size: 24px;
        width: 90%;
        margin: 0 10px;
        gap: 0;
        padding: 10px 0 20px;
    }
`

export const TxLinkTextContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding: 0;
    margin: 0;
`

export const TxLinkText = styled.p`
    font-family: 'SF Mono', sans-serif;
    color: rgba(0, 0, 0, 0.4);
    font-weight: 500;
    font-size: 12px;
    line-height: 29px;
    letter-spacing: -0.01em;
    text-transform: uppercase;
    margin: 0;
    @media (max-width: 768px) {
        text-align: center;
        font-size: 10px;
        font-weight: 400;
    }
`

export const TxLink = styled.a`
    font-family: 'SF Mono', sans-serif;
    color: rgba(0, 0, 0, 1);
    font-weight: 500;
    font-size: 14px;
    line-height: 14px;
    letter-spacing: -0.01em;
    text-transform: uppercase;
    text-decoration: none;
`

export const TxNumber = styled.p`
    position: absolute;
    top: 20px;
    left: 40px;
    font-family: 'SF Mono', sans-serif;
    font-weight: 400;
    font-size: 18px;
    text-transform: uppercase;
`

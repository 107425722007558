import { Modal, ModalContainer, ModalText, ButtonLinkWrapper, CloseButton } from "./NoWalletModal.styled"
import Button from "../Button"
import CloseIcon from '@mui/icons-material/Close'

function NoWalletModal({ isOpen, onClose }) {
    const dappUrl = window.location.href.split('//')[1]
    const metamaskAppDeepLink = 'https://metamask.app.link/dapp/' + dappUrl
    return <Modal isOpen={isOpen}>
        <ModalContainer>
            <CloseButton onClick={onClose}>
                <CloseIcon />
            </CloseButton>
            <ModalText>We didn&apos;t find your wallet </ModalText>
            <ButtonLinkWrapper href={metamaskAppDeepLink} target="_blank" rel="noreferrer">
                <Button>Install Metamask</Button>
            </ButtonLinkWrapper>
        </ModalContainer>
    </Modal>
}

export default NoWalletModal
import { createGlobalStyle } from "styled-components"

import RobotoMonoMedium from "./RobotoMono/RobotoMono-Medium.ttf"
import RobotoMonoRegular from "./RobotoMono/RobotoMono-Regular.ttf"

import SFMonoRegular from "./SFMono/SFMono-Regular.ttf"
import SFMonoSemibold from "./SFMono/SFMono-Semibold.ttf"

const GlobalFonts = createGlobalStyle`
    @font-face {
        font-family: 'Roboto Mono';
        src: local('Roboto Mono'), local('Roboto Mono Medium'),
        url(${RobotoMonoMedium}) format('truetype');
        font-weight: 500;
        font-style: normal;
        font-display: swap;
    }

    @font-face {
        font-family: 'Roboto Mono';
        src: local('Roboto Mono Regular'),
        url(${RobotoMonoRegular}) format('truetype');
        font-weight: 400;
        font-style: normal;
        font-display: swap;
    }

    @font-face {
        font-family: 'SF Mono';
        src: local('SF Mono Semibold'),
        url(${SFMonoSemibold}) format('truetype');
        font-weight: 700;
        font-style: normal;
        font-display: swap;
    }

    @font-face {
        font-family: 'SF Mono';
        src: local('SF Mono'), local('Roboto Mono Regular'),
        url(${SFMonoRegular}) format('truetype');
        font-weight: 500;
        font-style: normal;
        font-display: swap;
    }
`

export { GlobalFonts }

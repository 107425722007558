import { HOST } from "../constants"

export async function getTxStatus(txHash) {
    const body = {
        jsonrpc: "2.0",
        method: "getStatus",
        id: 1,
        params: {
            "tx": txHash,
        },
    }
    return await fetch(HOST, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
    })
        .then((response) => {
            if (response.ok) {
                return response
            }
            throw new Error(`Error: ${response.status}`)
        })
        .then((response) => response.json())
        .catch((err) => {
            throw new Error(err)
        })
}
